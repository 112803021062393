import React, { useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { Breadcrumb, Select, message, Tooltip } from 'antd'
import { sortBy, get, groupBy } from 'lodash'
import axios from 'axios'
import { ProductLabels } from '../utils/config.js'
import { getVersionDescLang, compareVersions, formatCPU } from '../utils/methods.js'

import Seo from '../components/seo'
import WLoading from "../components/wLoading"
import Icon from '../components/iconfontCpt'
import Layout from "../components/layout"

import '../style/mogdbdownload.scoped.scss'

// 初始内容获取
const ProductHistoryDownLoad = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? '/en' : ''
  const apiPrefix = process.env && process.env.NODE_ENV === 'development' ? 'http://jenkins.mogdb.enmotech.com:7004/api' : '/api'
  const downloadPrefix = "https://cdn-mogdb.enmotech.com/"
  const docPrefix = `https://docs.mogdb.io/${pageLang}/`

  const productKey = pageContext.key
  const pageKey = ProductLabels[productKey]

  useEffect(() => {
    getVersions()
  }, [])

  const [version, setVersion] = useState('')
  const [curVersionDocUrl, setCurVersionDocUrl] = useState('')
  const [curVersionLang, setCurVersionLang] = useState('')
  const [curVersionDate, setCurVersionDate] = useState('')

  const [versionListData, setVersionList] = useState([])
  const [packages, setPackages] = useState([])
  const [loading, setLoading] = useState(false); // 版本loading
  const [loading2, setLoading2] = useState(false); // 包loading

  // 获取当前产品下的版本信息
  async function getVersions() {
    setLoading(true)
    const res = await axios.get(apiPrefix + '/public/products');
    if (res && res.data && res.data.code === 200) {
      const productData = get(res, 'data.data.list', []).find(p => p.product.toLowerCase() === productKey.toLowerCase())
      if (!productData) {
        setLoading(false)
        return
      }
      // 根据产品ID获取版本信息
      const versionsRes = await axios.get(`${apiPrefix}/public/versions?pageLimit=1000&pageNum=1&productId=${productData.id}`);
      setLoading(false)
      if (versionsRes && versionsRes.data && versionsRes.data.code === 200) {
        let versions = get(versionsRes, 'data.data.list', [])
        versions = versions.sort(compareVersions).reverse()
        setVersionList(versions)

        const firstItem = versions[0] || {}
        setCurVersionDocUrl(firstItem.docUrl || '')
        setCurVersionLang(getVersionDescLang(firstItem, pageLang))
        setCurVersionDate(firstItem.releaseDate)

        handleVersionChange(firstItem.id)
      } else {
        message.error(versionsRes.data && versionsRes.data.message || 'error');
      }
    } else {
      setLoading(false)
      message.error(res.data && res.data.message || 'error');
    }
  }

  // 处理版本变更
  function handleVersionChange(id) {
    setVersion(id)
    getPackages(id)
    if (versionListData.length === 0) return
    const curVersion = versionListData.find(v => v.id === id) || {}
    setCurVersionLang(getVersionDescLang(curVersion, pageLang))
    setCurVersionDocUrl(curVersion.docUrl || '')
    setCurVersionDate(curVersion.releaseDate)
  }

  // 根据版本获取安装包
  async function getPackages(versionID) {
    setLoading2(true)
    const res = await axios.get(`${apiPrefix}/public/versions/${versionID}/packages?pageLimit=1000&pageNum=1`);
    setLoading2(false)
    if (res && res.data && res.data.code === 200) {
      let list = get(res, 'data.data', []).filter(i => i.packages && i.packages.length > 0)
      
      const OsArr = ['Linux', 'Kylin']
      list=sortBy(list, p => OsArr.findIndex(t => t.toLowerCase() === p.os.os.toLowerCase()))
      setPackages(list)
    } else {
      message.error(res.data && res.data.message || 'error');
    }
  }

  // 跳转当前版本对应的详细文档
  function goDoc() {
    const w = window.open('_black')
    w.location.href = docPrefix + curVersionDocUrl
  }

  // 获取下载列表
  const TypeArr = ['Server', 'Toolkits', 'Plugins', 'Agent', 'Proxy']
  function getPackageList(data) {
    if (!data || data.length === 0) return
    const formatData = data.map(d => {
      return {
        ...d,
        _langDes: getVersionDescLang(d, pageLang),
        isEncrypted: d.md5 || d.sha1 || d.sha256
      }
    })
    const groupData = groupBy(formatData, 'fileType')
    const groupKeys = sortBy(Object.keys(groupData), p => TypeArr.findIndex(t => t.toLowerCase() === p.toLowerCase()))

    return groupKeys.map(group => <div className="package-item" key={group}>
      {group && <div className="package-type"><FormattedMessage id={`download.${group.toLowerCase()}_mini`} /></div>}
      <div className={`package-list ${groupData[group].length > 3 ? 'use-more' : ''}`}>
        {groupData[group].length > 3 &&
          <>
            <input type="checkbox" className="show-check" />
            <div className="replace-check show"><FormattedMessage id="download.pluginsMore" /> &gt;&gt;</div>
            <div className="replace-check hidden"><FormattedMessage id="download.pluginsHide" /> &lt;&lt;</div>
          </>
        }
        {groupData[group].map((s, sIdx) =>
          <div key={sIdx} className={`${sIdx > 2 ? 'more-package package' : 'package'}`}>
            <div className="label">{s.fileName}</div>
            {s._langDes && <div className="desc" dangerouslySetInnerHTML={{ __html: s._langDes }}></div>}
            <div className="icon-btns">
              {s.docUrl && <a target="_blank" className="icon-btn" href={docPrefix + s.docUrl}><Icon type="icon-file" size={12} /></a>}
              {s.downloadUrl && <a className="icon-btn" href={downloadPrefix + s.downloadUrl}><Icon type="icon-download" size={12} /></a>}

              {s.isEncrypted &&
                <Tooltip placement="left" title={formatMda(s)}>
                  <span className="icon-btn" ><Icon type="icon-sha256" size={12} /></span>
                </Tooltip>
              }
            </div>
          </div>
        )}
      </div>
    </div>)
  }

  function formatMda(d) {
    return (
      <div className="md5-box">
        {d.md5 && (
          <div className="md5-item">
            <div className="md5-label">MD5:</div>
            <div className="md5-content">{d.md5}</div>
          </div>
        )}
        {/* {d.sha1 && (
          <div className="md5-item">
            <div className="md5-label">SHA1:</div>
            <div className="md5-content">{d.sha1}</div>
          </div>
        )} */}
        {d.sha256 && (
          <div className="md5-item">
            <div className="md5-label">SHA256:</div>
            <div className="md5-content">{d.sha256}</div>
          </div>
        )}
      </div>
    )
  }

  return (
    <Layout pageLang={pageLang} showHeader={true} showFooter={true}>
      <Seo title={`${pageKey}: ${pageKey} Downloads`} />
      <div className="banner-container">
        <div className="banner-box">{pageKey} <FormattedMessage id="download.download" /></div>
      </div>
      <WLoading spinning={loading}>
        <div className="download-container">
          <div className="title">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href={`${pagePrefix}/download/`}><FormattedMessage id="header.download" /></a>
              </Breadcrumb.Item>
              {['mogdb', 'uqbar'].includes(productKey) &&
                <Breadcrumb.Item>
                  <a href={`${pagePrefix}/downloads/${productKey}/`}><FormattedMessage id="download.downloadDetail" /></a>
                </Breadcrumb.Item>}
              <Breadcrumb.Item><FormattedMessage id="download.downloadHistory" /></Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="privacy-tip"><Icon type="icon-explain" size={14} /><span><FormattedMessage id="download.licenseLabel1" /></span><a href="/privacy/" target="_blank" className="text-btn">&nbsp;<FormattedMessage id="download.licenseLabel2" /></a></div>

          <div className="tab">
            {/* 移动端处理 */}
            <div className="mobile-version-box">
              <div className="item-label">
                <FormattedMessage id="download.version" />：
                <div className="history-download" onClick={goDoc}>
                  <Icon type="icon-file" size={10} />
                  <span><FormattedMessage id="productDoc.moreLink" /></span>
                </div>
              </div>
              <div className="cpu-box large">
                {versionListData.map(item => (
                  <div className={`cpu-card ${version === item.id ? 'is-active' : ''}`} onClick={() => handleVersionChange(item.id)} key={item.id}>{`${item.version}${item.label ? ' (' + item.label + ')' : ''}`}</div>
                ))}
              </div>
            </div>

            <div className="version-drop">
              <span className="version-drop-label item-label"><FormattedMessage id="download.version" /></span>
              <Select value={version} style={{ width: 250 }} onChange={handleVersionChange}>
                {versionListData.map(item => (
                  <Select.Option value={item.id} key={item.id}>{`${item.version}${item.label ? ' (' + item.label + ')' : ''}`}</Select.Option>
                ))}
              </Select>
              {curVersionDocUrl && <div className="history-download" onClick={goDoc}>
                <Icon type="icon-file" size={12} />
                <span><FormattedMessage id="productDoc.moreLink" /></span>
              </div>}
            </div>

            {(curVersionDate || curVersionLang) && <div className="tips">
              {curVersionDate && <div className="download-tip version-tip"><FormattedMessage id="productDoc.versionDateTip" />{curVersionDate}</div>}
              {curVersionLang && <div className="download-tip version-tip"><FormattedMessage id="productDoc.versionTip" />{curVersionLang}</div>}
            </div>}

            <WLoading spinning={!loading && loading2}>
              {packages.map((group, idx) =>
                <div className="version-card" key={idx}>
                  <div className="os-list">
                    <div className="mobild">
                      {idx + 1}、{get(group, 'os.os') + ' ' + formatCPU(get(group, 'os.cpu'))}{group.supportOS.map(osItem => <span key={osItem.id}>{', ' + osItem.os + ' ' + formatCPU(osItem.cpu)}</span>)}
                    </div>
                    <div className="pc">
                      <div>{get(group, 'os.os') + ' ' + formatCPU(get(group, 'os.cpu'))}</div>
                      {group.supportOS.map(osItem => <div key={osItem.id}>{osItem.os + ' ' + formatCPU(osItem.cpu)}</div>)}
                    </div>
                  </div>
                  <div className="package-content">
                    {getPackageList(group.packages)}
                  </div>
                </div>
              )}
            </WLoading>
          </div>
        </div>
      </WLoading>
    </Layout>
  )
}

export default ProductHistoryDownLoad